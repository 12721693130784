.achievement {
    text-align: center;

    @include media-breakpoint-down(sm) {
        font-size: 24px;
    }

    .achievement__number, .achievement__text {
        font-family: $font-secondary;
        font-weight: bold;
        font-size: 70px;
        line-height: 60px;
        color: $green-light;

        @include media-breakpoint-down(sm) {
            font-size: 54px;
            line-height: 54px;
        }
    }

    .achievement__text {
        font-size: 34px;
        letter-spacing: 2.2px;
        margin-top: 12px;

        @include media-breakpoint-down(sm) {
            font-size: 28px;
            letter-spacing: 1.5px;
            margin-top: 0;
        }
    }
}