.footer {
    background-color: $green-dark;
    padding-top: 50px;
    padding-bottom: 30px;

    .contact_link {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
            margin-top: 10px;
        }

        .icon {
            font-size: 24px;
            margin-right: 10px;
        }
    }

    .contact_logo {
        height: 90px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }
    }

    .contact_form_wrapper {
        max-width: 445px;
    }
} 