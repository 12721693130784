// ############ Colors ############

$white:     #ffffff;
$gray-light:#E5E5E5;
$gray:      #4F4A3D; 
$black:     #000000;

$red:           #CC1C34;
$orange:        #FF6933;
$orange-light:  #FFA633;
$green-light:   #CFF8E5;
$green-dark:    #23B070;