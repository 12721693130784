.section_cookie {
    position: fixed;
    bottom: 0;
    padding: 10px 0;
    box-shadow: 0px 3px 10px rgba($black, 0.2);
    z-index: 4;

    @include media-breakpoint-down(sm) {
        text-align: center;
    }

    .cookie__content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }
    }

    .cookie__form {
        display: flex;
        flex-wrap: nowrap;

        @include media-breakpoint-down(sm) {
            width: 100%;
            justify-content: center;
            margin-top: 15px;
        }
    }

    .btn {
        margin: 0 10px;
        min-width: 110px;
    }
}