.subscribe_form {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    @include media-breakpoint-down(sm) {
        justify-content: center;
        flex-wrap: wrap;
    }
}

.subscribe_input {
    font-family: $font-primary;
    font-size: 18px;
    line-height: 22px;
    color: $white;
    padding: 8px 26px;
    background: transparent;
    border: 1px solid $white;
    border-radius: 40px 0 0 40px;
    margin-top: 0px;
    width: 100%;

    &:focus {
        color: $white;
        border-color: $white;
    }

    @include placeholder {
        color: $white;
        opacity: $letter-opacity;
    }

    @include media-breakpoint-down(sm) {
        border-radius: 40px;
    }
}

/* IE11    */ 
_:lang(x)::-ms-backdrop, .subscribe_input {
    padding-top: 7px;
    padding-bottom: 7px;
}

.subscribe_btn {
    margin-top: 0;
    margin-left: -20px;

    @include media-breakpoint-down(md) {
        min-width: 150px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 20px;
        margin-left: 0px;
    }
}