.section-form-project {
    max-width: 730px;
    margin: 0 auto;

    .steps {
        display: flex;
        margin-top: 45px;
        border-bottom: 1px solid $gray-light;


        .step {
            @extend %flex-center;
            position: relative;
            width: 100%;
            font-family: $font-secondary;
            font-weight: bold;
            font-size: 24px;
            line-height: 30px;
            padding-bottom: 20px;

            &.active-step {
                color: $orange;

                .step-active__line {
                    display: inline-block;
                }
            }
        }

        .step-active__line {
            position: absolute;
            left: 0;
            bottom: -1px;
            display: none;
            width: 100%;
            height: 4px;
            background-color: $orange;
            border-radius: 2px;
        }
    }
}