.item {
    margin-top: 62px;

    @include media-breakpoint-down(md) {
        margin-top: 50px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 50px;
    }
    
    .item__title {
        font-family: $font-secondary;
        font-weight: bold;
        margin-top: 26px;
    
        @include media-breakpoint-down(md) {
            margin-top: 20px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 15px;
        }
    }

    .text_default {
        margin-top: 20px;

        @include media-breakpoint-down(md) {
            max-width: 500px;
            margin: 15px auto 0;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 10px;
        }
    }
}