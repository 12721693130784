@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?9ev4ww') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?9ev4ww') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?9ev4ww##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-arrow-top {
  &:before {
    content: $icon-arrow-top; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}

