.intro_wrapper {
    position: relative;
    height: 100%;
}

.swiper-container-intro {
    height: 100%;

    .slide-img {
        height: 100%;
    }

    .swiper-pagination {
        bottom: 55px;

        @include media-breakpoint-down(sm) {
            bottom: 30px;
        }

        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            background: $white;
            margin: 0 7px;

            @include media-breakpoint-down(sm) {
                width: 10px;
                height: 10px;
            }
        }
    }
}

.section_intro {
    @extend %flex-center;
    flex-direction: column;
    min-height: 580px;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background: url('#{$path-img}chart.png') no-repeat;
    background-position: right center;

    @include media-breakpoint-down(lg) {
        background-position-y: bottom;
        background-size: 75% auto;
    }

    @include media-breakpoint-down(sm) {
        background: none;
        text-align: center;
    }

    .text_md {
        max-width: 600px;

        @include media-breakpoint-down(sm) {
            margin-top: 10px;
        }
    }
}

/* IE11    */ 
_:lang(x)::-ms-backdrop, .section_intro {
    min-height: inherit;
}