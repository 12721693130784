.achievement-sm {
    text-align: center;
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;

    &.achievement__first-child {

        .vertical-line {
            display: none;
        }
    }

    @include media-breakpoint-down(md) {
        padding-left: 0px;
        padding-right: 0px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 24px;
    }

    .vertical-line {
        position: absolute;
        top: 40px;
        left: 0;
        display: inline-block;
        width: 1px;
        height: 110px;
        background-color: $white;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .achievement-sm__number {
        font-family: $font-secondary;
        font-weight: bold;
        font-size: 48px;
        line-height: 1.1em;
        color: $white;

        @include media-breakpoint-down(sm) {
            font-size: 40px;
        }

        span {
            font-size: 24px;
        }
    }

    .achievement-sm__text {
        color: $white;
        margin-top: 20px;

        @include media-breakpoint-down(sm) {
            margin-top: 5px;
            line-height: 25px;
        }
    }
}