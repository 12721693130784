.logo_card {
    margin-top: 46px;

    @include media-breakpoint-down(sm) {
        margin-top: 24px;
    }
    
    .logo_card__img {
        width: 100%;
        transition: all 0.3s;

        &:hover {
            box-shadow: 0px 0px 10px rgba($green-dark, 0.2);
        }
    
        @include media-breakpoint-down(sm) {
            max-width: 320px;
        }
    }
}