$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-menu: "\e900";
$icon-arrow-top: "\e901";
$icon-close: "\e902";
$icon-email: "\e903";
$icon-facebook: "\e904";
$icon-linkedin: "\e905";
$icon-location: "\e906";
$icon-phone: "\e907";
$icon-search: "\e909";
$icon-twitter: "\e90a";

