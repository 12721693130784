.service-wrapper {
    margin-top: 120px;

    @include media-breakpoint-down(sm) {
        margin-top: 70px;
    }
}

.service {
    @extend %flex-center;
    flex-wrap: wrap;
    padding: 35px 73px;
    color: $white;
    text-align: center;
    min-height: 320px;
    height: 100%;

    @include media-breakpoint-down(md) {
        padding-left: 25px;
        padding-right: 25px;
    }

    @include media-breakpoint-down(sm) {
        min-height: auto;
    }

    .service__title {
        font-family: $font-secondary;
        font-weight: bold;
        font-size: 30px;
        line-height: 1.2em;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        @include media-breakpoint-down(sm) {
            font-size: 26px;
        }
    }

    .service__text {
        font-size: 18px;
        line-height: 25px;
        margin-top: 25px;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
            line-height: 22px;
        }
    }
}

.bg_green-dark {
    background-color: $green-dark;
}

.bg_orange {
    background-color: $orange;
}