.section_map {
    padding-bottom: 0;

    .contact_link {
        display: flex;
        align-items: center;
        margin-top: 0px;
        margin-bottom: 35px;
        line-height: 25px;
        color: $green-dark;

        &:last-child {
            margin-bottom: 35px;
        }

        &[href]:hover {
            color: $orange;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
        }

        .icon {
            color: $orange;
            margin-right: 14px;
        }
    }
}