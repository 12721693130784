*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    height: 100%;
    position: relative;
    font: #{$default-font-weight} #{$default-font-style} #{$default-font-size} / #{$default-line-height} #{$default-font-family};
    color: $default-color;
    background-color: $default-bg;
    font-weight: $default-font-weight;

    @include media-breakpoint-down(sm) {
        font-size: 16px;
    }

    &.overflow-hidden {
        overflow: hidden;
    }
}

.layout {
    position: relative;
    overflow: hidden;
    min-height: 100%;
}  

/** BG **/

.container-fluid {
    position: relative;
}

.container__bg-light {
    background-color: $green-light;
}

.container__bg-green {
    background-color: $green-dark;
}

.container__gradient-light {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($green-light, 0.7);
}

.container__gradient-orange {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($orange, 0.5);
}

.container__gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($green-dark, 0.5);
}

.container__gradient-wrapper {
    position: relative;
}

.container-full {
    display: none;
    opacity: 0;
    height: 100%;
    position: absolute;
    top: -5%;
    z-index: 5;
    overflow-y: auto;
}

.container {
    position: relative;
}

/** Typography **/

p {
    margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-primary;
    font-style: normal;
    color: $gray;
    margin-bottom: 0;
    line-height: 1;
}

.title {
    font-family: $font-secondary;
    font-weight: bold;
    font-size: 48px;
    line-height: 1.25em;
    color: $white;
    text-transform: capitalize;

    @include media-breakpoint-down(sm) {
        font-size: 32px;
    }
}

.title_section {
    font-family: $font-secondary;
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
    text-transform: uppercase;
    margin-top: 15px;
    letter-spacing: 0.5px;

    &.normal {
        font-weight: 400;
        text-transform: none;
    }

    @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 26px;
        margin-top: 10px;
    }
}

.subtitle_section {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px; 
    color: $orange-light;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        font-size: 16px;
        line-height: 20px;
    }
}

.text_section {
    line-height: 25px;
    margin-top: 25px;
}

.text_default {
    line-height: 25px;
    opacity: $letter-opacity;
    margin-top: 30px;

    @include media-breakpoint-down(sm) {
        line-height: 22px;
    }
}

.text_sm {
    font-size: 14px; 
    line-height: 18px;
}

.text_md {
    font-size: 24px;
    line-height: 33px;

    @include media-breakpoint-down(sm) {
        font-size: 18px;
        line-height: 22px;
    }
}

.text_white {
    color: $white;
}

.text_opacity {
    opacity: $letter-opacity;
}

.text__wrapper {
    max-width: 610px;
    margin-top: 24px;

    @include media-breakpoint-down(sm) {
        margin-top: 15px;
    }
}

/** Buttons **/

a {
    text-decoration: none;
    transition-duration: .15s;

    &:hover {
        color: $orange;
        text-decoration: none;
    }
}

button {
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    width: auto;
    background-color: transparent;
    cursor: pointer;

    &:focus {
        outline: none; 
    }
}

.btn { 
    margin-top: 25px;
    box-sizing: border-box;
    text-transform: uppercase;
    color: $white;
    background-image: linear-gradient(to left, $orange 0%, $orange-light 51%, $orange 100%);
    transition: .5s;
    background-size: 200% auto;
    box-shadow: 0px 0px 20px rgba($orange-light, 0.2);
    min-width: 180px;

    &:hover {
        color: $white;
        background-position: right center; 
    }

    &.btn_white {
        color: $green-dark;
        background: $white;
    }

    &.btn_transparent {
        color: $white;
        background: transparent;
        border: 1px solid $white;
    }

    &:focus {
        box-shadow: none !important;
    }
}  

/** Form Elements **/

input, textarea, .form-control {
    padding: 10px;
    height: auto;
    font-size: 14px;
    line-height: 18px;
    margin-top: 6px;
    border: 1px solid $gray-light;
    border-radius: 5px;

    &:focus {
        outline: none;
        box-shadow: none;
        color: $gray;
        border-color: $green-dark;
    }

    &.incorrect {
        border-color: $red;
    }

    @include placeholder {
        color: rgba($gray, 0.5);
    }

    @include media-breakpoint-down(sm) {
        font-size: 16px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.form-textarea {
    height: 120px;
    max-height: 120px;
    min-height: 120px;
    resize: none;
}

.form-label {
    display: block;
    font-size: 14px;
    line-height: 19px;
    color: $green-dark;
    margin-top: 20px;
}

.form {
    margin-top: 42px;

    @include media-breakpoint-down(sm) {
        margin-top: 20px;
    }

    .btn {
        margin-top: 40px;
    }
}

/** Spacing **/

.offset_top-lg {
    margin-top: 72px;
}

.offset_top {
    margin-top: 50px;
}