.section_management {

    .title_section {
        margin-top: 0;
    }

    .text_section {
        max-width: 850px;
        margin: 35px auto 0;
    }
}

.manager__wrapper {
    margin-top: 60px;
    display: flex;

    &.first-child {
        margin-top: 132px;

        @include media-breakpoint-down(sm) {
            margin-top: 80px;
            flex-direction: column;
        }
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
    }

    .manager__container {
        display: flex;
        align-items: center;
        background-color: $green-light;
    }

    .manager__img-wrapper {
        min-width: 365px;

        @include media-breakpoint-down(md) {
            min-width: 300px;
        }

        @include media-breakpoint-down(sm) {
            min-width: 100%;
        }
    }

    .manager__img-description {
        background-color: $orange;
        min-height: 117px;
        padding: 24px;
        margin-top: -2px;
        
    }

    .manager__img-name {
        font-family: $font-secondary;
        font-weight: bold;
        font-size: 24px;
        line-height: 1.25em;
    }

    .manager__img-position {
        line-height: 1.25em;
        margin-top: 5px;
    }

    .manager__quotation-wrapper {
        position: relative;
        background-color: $green-light;
        display: flex;
        align-items: center;
        padding: 80px 70px;

        @include media-breakpoint-down(md) {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    .manager__quotation {
        margin-top: 0;
    }
    
    .manager__quotes {
        position: absolute;
        top: 43px;
        left: 48px;

        @include media-breakpoint-down(md) {
            top: 30px;
            left: 35px;
        }
    }

    .manager__quotes-last{
        position: absolute;
        bottom: 43px;
        right: 48px;
        transform: rotate(180deg);
    }
}