.section_our-practice {
    background-repeat: no-repeat;
    background-size: 55% auto;
    background-position: left bottom;
    background-color: $green-light;

    @include media-breakpoint-down(md) {
        background-image: none !important;
        background-color: $green-light;
    }
}