.arrow_go-top {
    @extend %flex-center;
    position: fixed;
    right: 50px;
    bottom: 50px;
    height: 60px;
    width: 60px;
    background-color: $orange;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
        display: none;
    }
}