.swiper-container-reviews {
    padding-top: 20px;

    @include media-breakpoint-down(sm) {
        padding-bottom: 10px;
    }

    .swiper-slide {
        cursor: grab;

        .slide-content {
            position: relative;
            max-width: 680px;
            min-height: 320px;
            margin: 0 auto;
            padding: 40px 75px;

            @include media-breakpoint-down(md) {
                max-width: 570px;
                padding-left: 60px;
                padding-right: 60px;
            }

            @include media-breakpoint-down(sm) {
                padding: 20px 30px;
                min-height: auto;
            }
        }

        .slide-quotes {
            position: absolute;
            top: 105px;
            left: 0px;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            &.slide-quotes-last {
                transform: rotate(180deg);
                top: auto;
                left: auto;
                bottom: 0;
                right: 0;
            }
        }

        .slide-text {
            margin-top: 28px;

            @include media-breakpoint-down(sm) {
                margin-top: 10px;
                font-size: 12px;
                line-height: 18px;
            }
        }
        
        .wrapper__round-img {
            margin: 0 auto;
            border-radius: 50%;
            border: 1px solid $orange;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            overflow: hidden;
            padding: 0;
            width: 90px;
            height: 90px;
            box-sizing: border-box;

            .round-img {
                border-radius: 50%;
                width: auto;
                height: 100%;
            }

            @include media-breakpoint-down(sm) {
                bottom: -35px;
                margin-top: 15px;
                width: 80px;
                height: 80px;
            }
        }
    }
}

.navigation {

    .swiper-btn-prev, .swiper-btn-next {
        position: absolute;
        top: 44%;
        transform: translateY(-50%);
        z-index: 3;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
    
    .swiper-btn-prev {
        left: 0;
    }

    .swiper-btn-next {
        right: 0;

        .slide-arrow-last {
            transform: rotate(180deg);
        }
    }

    .swiper-btn-prev.swiper-button-disabled,
    .swiper-btn-next.swiper-button-disabled {
        opacity: $letter-opacity;
        cursor: default;
    }

    .swiper-btn-prev {
        font-size: 24px;
    }
}