.component_menu {

    .container {
        position: relative;
        height: 100%;
        padding-top: 120px;
    }

    .flex__content {
        position: absolute;
        top: 0;
        margin: 0 -15px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 5px;

        @include media-breakpoint-down(sm) {

            .header__logo {   
                height: 70px;
            }
        }
    }

    .right-col {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    .icon {
        @extend %hover-effect;
    }

    .menu__content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
            margin-top: 20px;
        }

        .text_sm {
            margin-top: 24px;
        }
        
        .menu__left-col {
            min-width: 400px;

            @include media-breakpoint-down(lg) {
                min-width: 370px;
            }

            @include media-breakpoint-down(sm) {
                min-width: 100%;
                text-align: center;
            }
        }

        .menu__center-col {
            min-width: 215px;
            margin-left: 30px;
            margin-right: 30px;

            @include media-breakpoint-down(sm) {
                min-width: 100%;
                margin: 0;
                text-align: center;
            }
        }

        .menu__right-col {
            max-width: 365px;
            width: 100%;
            padding-bottom: 3em;

            @include media-breakpoint-down(md) {
                max-width: 100%;
                width: 100%;
                border-top: 1px solid $white;
                margin-top: 2.5em;
                padding-top: 30px;
                display: flex;

                .menu__wrapper {
                    min-width: 40%;
                    
                    &:last-child {
                        border-left: 1px solid $white;
                        padding-left: 20px;
                    }

                }
            }

            @include media-breakpoint-down(xs) {
                flex-wrap: wrap;

                .menu__wrapper {
                    min-width: 100%;
                    
                    &:last-child {
                        border-top: 1px solid $white;
                        border-left: 0;
                        padding-left: 0px;

                    }

                }
            }
        }
    }

    @include media-breakpoint-down(sm) {

        .navbar-nav-sm {
            width: 100%;
        }
    }

    .nav-item {
        border-bottom: 1px solid $white;
        margin: 20px 0 28px;

        &:first-child {
            margin-top: 0px;
        }

        @include media-breakpoint-down(md) {
            &:last-child {
                border-bottom: 0;
            }
        }

        @include media-breakpoint-down(sm) {
            &:last-child {
                border-bottom: 1px solid $white;
            }
        }

        @include media-breakpoint-down(xs) {
            margin-top: 0;
        }
    }

    .nav-link {
        font-family: $font-secondary;
        font-weight: bold;
        font-size: 36px;
        line-height: 45px;
        color: $white;
        padding-top: 0;
        padding-bottom: 18px;
        @extend %hover-effect;

        @include media-breakpoint-down(sm) {
            font-size: 30px;
            line-height: 34px;
        }
    }

    .nav-item-sm {
        margin: 65px 0;

        @include media-breakpoint-down(sm) {
            margin: 40px 0;
        }

        @include media-breakpoint-down(xs) {
            margin: 20px 0;
        }
    }

    .nav-link-sm {
        font-family: $font-secondary;
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
        color: $white;
        opacity: $letter-opacity;
        padding-top: 10px;
        padding-bottom: 10px;
        transition-duration: .15s;

        &:hover {
            opacity: 1;
        }

        @include media-breakpoint-down(sm) {
            font-size: 26px;
            line-height: 30px;
        }
    }

    .menu__wrapper {

        &:first-child {

            .menu__subtitle {
                margin-top: 5px;
            }

            @include media-breakpoint-down(md) {
                .menu__subtitle {
                    margin-top: 0px;
                }
            }
        }

        .menu__subtitle {
            font-family: $font-secondary;
            font-weight: bold;
            font-size: 30px;
            line-height: 1.2em;
            letter-spacing: 0.5px;
            margin-top: 46px;
    
            @include media-breakpoint-down(md) {
                margin-top: 0px;
            }
    
            @include media-breakpoint-down(sm) {
                font-size: 26px;
            }
    
            @include media-breakpoint-down(xs) {
                margin-top: 30px;
            }
        }
    }


    .social-network__wrapper {
        margin-top: 25px;
        border-bottom: 1px solid $white;
        padding-bottom: 30px;

        @include media-breakpoint-down(md) {
            border-bottom: 0;
        }

        .social-network{
            margin-right: 60px;

            @include media-breakpoint-down(md) {
                margin-right: 40px;
            }

            .icon {
                font-size: 22px;
            }
        }
    }

    .subscribe_form {
        @include media-breakpoint-down(lg) {
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    .subscribe_input {
        @include media-breakpoint-down(lg) {
            border-radius: 40px;
        }
    }

    .subscribe_btn {
        @include media-breakpoint-down(lg) {
            margin-top: 20px;
            margin-left: 0px;
            min-width: 100%;
        }
    }
}