.component_search {

    .container {
        position: relative;
        height: 100%;
    }

    .flex__content {
        position: absolute;
        top: 0;
        width: 100%;
        margin: 0 -15px;
        display: flex;
        justify-content: space-between;
        padding-top: 5px;

        @include media-breakpoint-down(sm) {

            .header__logo {   
                height: 70px;
            }
        }
    }

    .right-col {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    .icon {
        @extend %hover-effect;
    }

    .search__content {
        @extend %flex-center;
        height: 100%;
    }

    .search__form {
        position: relative;
        transform: translateY(-50%);
    }

    .search__input {
        background: transparent;
        font-family: $font-secondary;
        font-weight: bold;
        font-size: 36px;
        line-height: 45px;
        border-radius: 0;
        color: $white;
        width: 730px;
        border: none;
        border-bottom: 1px solid $white;
        padding: 8px 65px 9px 0;
        margin-top: 0px;

        &:focus {
            padding-bottom: 8px;
            border-bottom-width: 2px;
        }

        @include media-breakpoint-down(lg) {
            width: 650px;
        }

        @include media-breakpoint-down(md) {
            width: 550px;
        }

        @include media-breakpoint-down(sm) {
            width: 450px;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            font-size: 24px;
            line-height: 34px;
        }
    }

    /* clears the 'X' from Internet Explorer */
    .search__input::-ms-clear {  
        display: none; 
        width : 0; 
        height: 0; 
    }
    .search__input::-ms-reveal {  
        display: none; 
        width : 0; 
        height: 0; 
    }

    /* clears the 'X' from Chrome */
    .search__input::-webkit-search-decoration,
    .search__input::-webkit-search-cancel-button,
    .search__input::-webkit-search-results-button,
    .search__input::-webkit-search-results-decoration { 
        display: none; 
    }

    .search__btn {
        position: absolute;
        top: 0;
        right: 0;

        .icon {
            font-size: 58px;
            @extend %hover-effect;

            @include media-breakpoint-down(sm) {
                font-size: 46px;
            }
        }
    }
}