.section {
    padding-top: 94px;
    padding-bottom: 100px;

    @include media-breakpoint-down(sm) {
        padding-top: 70px;
        padding-bottom: 80px;

        .section_img__wrapper {
           margin-top: 30px; 
        }
    }
}