.section_header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;

    @include media-breakpoint-down(sm) {
        .header__logo__wrapper {
            width: 45px;
            overflow: hidden;
        }

        .header__logo {
            height: 70px;
        }
    }

    .flex__content {
        display: flex;
        justify-content: space-between;
        padding-top: 5px;
    }

    .change-language__wrapper {
        display: flex;
        position: relative;

        .vertical-line {
            display: inline-block;
            width: 1px;
            height: 20px;
            background-color: $white;
            margin-left: 7px;
            margin-right: 7px;
        }
    }

    .change-language {
        font-size: 18px;
        line-height: 21px;
        opacity: $letter-opacity;
        @extend %hover-effect;

        &.active-language {
            opacity: 1;
        }
    }

    .right-col {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .search__wrapper {
            margin-left: 35px;
            
            .icon {
                @extend %hover-effect;
            }
        }

        .menu__wrapper {
            margin-left: 15px;
            
            .icon {
                @extend %hover-effect;
            }
        }
    }
}

.section_header-relative {
    position: relative;
    background-color: $green-dark;
}