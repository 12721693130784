.criteria {
    margin-top: 40px;
    text-align: center;
    min-height: 135px;

    &:first-child {
        margin-top: 0px;
    }

    .criteria__img {
        background-repeat: no-repeat;
        background-size: contain;
        height: 60px;
        width: 60px;
        margin: 0 auto;
    }

    .criteria__text {
        margin-top: 20px;
    }
}