// Make centered via @extend
%flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

%hover-effect {
    transition-duration: .15s;
    cursor: pointer;

    &:hover {
        color: $orange;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

@mixin circle($size) {
    width: $size;
    height: $size;
    border-radius: $size;
}

