.section_intro-secondary {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    background-color: $green-light;

    .section {
        padding-top: 104px;
        padding-bottom: 80px;

        @include media-breakpoint-down(sm) {
            padding-top: 90px;
        }
    }

    .subtitle_section {
        font-size: 24px;
        line-height: 33px;

        @include media-breakpoint-down(sm) {
            font-size: 22px;
            line-height: 28px;
        }
    }

    .title_section {
        margin-top: 20px;
        font-size: 48px;
        line-height: 60px;

        @include media-breakpoint-down(sm) {
            margin-top: 16px;
            font-size: 36px;
            line-height: 42px;
        }
    }
}